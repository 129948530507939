import ChildView from "@/components/layout/ChildView";
import EmptyView from "@/components/layout/EmptyView";

const businessScene = {
    normal: "",
    tradeTray: "01",
    acting: "02",
    project: "03",
};

const viewRoutes = [
    {
        path: "/system",
        meta: {
            icon: "ios-alarm-outline",
            title: "系统",
            hide: true,
        },
        hide: true,
        component: ChildView,
        children: [
            {
                path: "/platform-application-list",
                title: "应用管理",
                meta: {
                    title: "应用管理",
                    icon: "md-albums",
                },
                componentPath: "platform/application-list",
                component: () => import("@/views/platform/application-list"),
            },
            {
                path: "/platform-resource-list",
                title: "资源管理",
                meta: {
                    title: "资源管理",
                    icon: "md-albums",
                },
                componentPath: "platform/resource-list",
                component: () => import("@/views/platform/resource-list"),
            },
            {
                path: "/platform-role-list",
                title: "角色管理",
                meta: {
                    title: "角色管理",
                    icon: "md-albums",
                },
                componentPath: "platform/role-template-list",
                component: () => import("@/views/platform/role-template-list"),
            },
            {
                path: "/platform-login-log-list",
                title: "登录日志",
                meta: {
                    title: "登录日志",
                    icon: "md-albums",
                },
                componentPath: "platform/login-log-list",
                component: () => import("@/views/platform/login-log-list"),
            },
            {
                path: "/platform-operation-log-list",
                title: "操作日志",
                meta: {
                    title: "操作日志",
                    icon: "md-albums",
                },
                componentPath: "platform/operation-log-list",
                component: () => import("@/views/platform/operation-log-list"),
            },
            {
                path: "/platform-auth-log-list",
                title: "权限日志",
                meta: {
                    title: "权限日志",
                    icon: "md-albums",
                },
                componentPath: "platform/auth-log-list",
                component: () => import("@/views/platform/auth-log-list"),
            },
            {
                path: "/platform-bill-list",
                title: "数据权限管理",
                meta: {
                    title: "数据权限管理",
                    icon: "md-albums",
                },
                componentPath: "platform/bill-list",
                component: () => import("@/views/platform/bill-list"),
            },
            {
                path: "/platform-interface-list",
                title: "接口管理",
                meta: {
                    title: "接口管理",
                    icon: "md-albums",
                },
                componentPath: "platform/interface-list",
                component: () => import("@/views/platform/interface-list"),
            },
            {
                path: "/platform-permission-list",
                title: "权限集管理",
                meta: {
                    title: "权限集管理",
                    icon: "md-albums",
                },
                componentPath: "platform/permission-list",
                component: () => import("@/views/platform/permission-list"),
            },
            {
                path: "/platform-agreement-list",
                title: "协议管理",
                meta: {
                    title: "协议管理",
                    icon: "md-albums",
                },
                componentPath: "platform/agreement-list",
                component: () => import("@/views/platform/agreement-list"),
            },
            {
                path: "/platform-version-list",
                title: "版本管理",
                meta: {
                    title: "版本管理",
                    icon: "md-albums",
                },
                componentPath: "platform/version-list",
                component: () => import("@/views/platform/version-list"),
            },
        ],
    },
    {
        path: "/operator",
        meta: {
            title: "运营",
        },
        title: "运营管理",
        component: ChildView,
        children: [
            {
                path: "/platform-company-list",
                title: "公司管理",
                meta: {
                    title: "公司管理",
                    icon: "md-albums",
                },
                componentPath: "platform/company-list",
                component: () => import("@/views/platform/company-list"),
            },
            {
                path: "/platform-authenticate-edit",
                meta: {
                    title: "公司认证审核-操作",
                    isFixedBtn: true,
                    icon: "md-albums",
                },
                hide: true,
                title: "公司认证审核-操作",
                componentPath: "platform/authenticate-list/edit",
                component: () =>
                    import(
                        /* webpackChunkName: "stock-warehouse-edit" */ "@/views/platform/authenticate-list/edit"
                    ),
            },
            {
                path: "/platform-list-authenticate",
                title: "公司认证审核",
                meta: {
                    title: "公司认证审核",
                    icon: "md-albums",
                },
                componentPath: "platform/authenticate-list",
                component: () => import("@/views/platform/authenticate-list"),
            },
            {
                path: "/platform-list-deal-date",
                title: "交易日期管理",
                meta: {
                    title: "交易日期管理",
                    icon: "md-albums",
                },
                componentPath: "platform/deal-date-list",
                component: () => import("@/views/platform/deal-date-list"),
            },
            {
                path: "/platform-user-list",
                title: "用户管理",
                meta: {
                    title: "用户管理",
                    icon: "md-albums",
                },
                componentPath: "platform/user-list",
                component: () => import("@/views/platform/user-list"),
            },
            {
                path: "/platform-staff-authenticate-list",
                title: "员工认证审核",
                meta: {
                    title: "员工认证审核",
                    icon: "md-albums",
                },
                componentPath: "platform/staff-authenticate-list",
                component: () =>
                    import("@/views/platform/staff-authenticate-list"),
            },
            {
                path: "/platform-staff-authenticate-edit",
                meta: {
                    title: "员工认证审核-操作",
                    isFixedBtn: true,
                    icon: "md-albums",
                },
                hide: true,
                title: "员工认证审核-操作",
                componentPath: "platform/staff-authenticate-list/edit",
                component: () =>
                    import(
                        /* webpackChunkName: "staff-authenticate-edit" */ "@/views/platform/staff-authenticate-list/edit"
                    ),
            },
            {
                path: "/platform-warehouse-audit-list",
                title: "仓库认证审核",
                meta: {
                    title: "仓库认证审核",
                    icon: "md-albums",
                },
                componentPath: "platform/warehouse-audit-list",
                component: () =>
                    import("@/views/platform/warehouse-audit-list"),
            },
            {
                path: "/platform-warehouse-audit-edit",
                meta: {
                    title: "仓库认证审核-操作",
                    isFixedBtn: true,
                    icon: "md-albums",
                },
                hide: true,
                title: "仓库认证审核-操作",
                componentPath: "platform/warehouse-audit-list/edit",
                component: () =>
                    import(
                        /* webpackChunkName: "staff-authenticate-edit" */ "@/views/platform/warehouse-audit-list/edit"
                    ),
            },
            {
                path: "/platform-pricing-list",
                title: "服务定价",
                meta: {
                    title: "服务定价",
                    icon: "md-albums",
                },
                componentPath: "platform/pricing-list",
                component: () => import("@/views/platform/pricing-list"),
            },
            {
                path: "/platform-pricing-history-list",
                title: "服务定价历史",
                meta: {
                    title: "服务定价历史",
                    icon: "md-albums",
                },
                componentPath: "platform/pricing-history-list",
                component: () =>
                    import("@/views/platform/pricing-history-list"),
            },
            {
                path: "/platform-warehouse-list",
                title: "仓库管理",
                meta: {
                    title: "仓库管理",
                    icon: "md-albums",
                },
                componentPath: "platform/warehouse-list",
                component: () => import("@/views/platform/warehouse-list"),
            },
            {
                path: "/order-operation-list",
                title: "订单管理",
                meta: {
                    title: "订单管理",
                    icon: "md-albums",
                },
                componentPath: "platform/order-operation-list",
                component: () =>
                    import("@/views/platform/order-operation-list"),
            },
            {
                path: "/whingers-tms-list",
                title: "质量异议",
                meta: {
                    title: "质量异议",
                    icon: "md-albums",
                },
                componentPath: "platform/whingers-tms-list",
                component: () => import("@/views/platform/whingers-tms-list"),
            },
            {
                path: "/find-process",
                title: "找加工",
                meta: {
                    title: "找加工",
                    icon: "md-albums",
                },
                componentPath: "platform/find-process",
                component: () => import("@/views/platform/find-process"),
            },
            {
                path: "/find-logistics",
                title: "找物流",
                meta: {
                    title: "找物流",
                    icon: "md-albums",
                },
                componentPath: "platform/find-logistics",
                component: () => import("@/views/platform/find-logistics"),
            },
        ],
    },
    {
        path: "/integral",
        meta: {
            title: "积分",
        },
        title: "积分",
        component: ChildView,
        children: [
            {
                path: "/platform-integral-list",
                title: "积分管理",
                meta: {
                    title: "积分管理",
                    icon: "md-albums",
                },
                componentPath: "platform/integral-list",
                component: () => import("@/views/integral/integral-manage"),
            },
            {
                path: "/integral-order-list",
                title: "订单管理",
                meta: {
                    title: "订单管理",
                    icon: "md-albums",
                },
                componentPath: "/integral-order-list",
                component: () => import("@/views/integral/order-list"),
            },
        ],
    },
    {
        path: "/mall",
        meta: {
            title: "商城管理",
        },
        title: "商城管理",
        component: ChildView,
        children: [
            {
                path: "/cms",
                title: "CMS管理",
                meta: {
                    title: "CMS管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-list-catalog",
                        meta: {
                            title: "栏目管理",
                            icon: "md-albums",
                        },
                        title: "栏目管理",
                        componentPath: "platform/catalog-list",
                        component: () =>
                            import("@/views/platform/catalog-list"),
                    },
                    {
                        path: "/platform-list-banner",
                        meta: {
                            title: "广告设置",
                            icon: "md-albums",
                        },
                        title: "广告设置",
                        componentPath: "platform/banner-list",
                        component: () => import("@/views/platform/banner-list"),
                    },
                    {
                        path: "/platform-list-news",
                        title: "商城动态",
                        meta: {
                            title: "商城动态",
                            icon: "md-albums",
                        },
                        componentPath: "platform/news-list",
                        component: () => import("@/views/platform/news-list"),
                    },
                    {
                        path: "/campus-dynamics",
                        title: "园区动态",
                        meta: {
                            title: "园区动态",
                            icon: "md-albums",
                        },
                        componentPath: "platform/campus-dynamics",
                        component: () => import("@/views/platform/campus-dynamics"),
                    },
                    {
                        path: "/platform-list-marketInformation",
                        title: "行情资讯",
                        meta: {
                            title: "行情资讯",
                            icon: "md-albums",
                        },
                        componentPath: "platform/information-list",
                        component: () =>
                            import("@/views/platform/information-list"),
                    },
                    {
                        path: "/platform-list-problem",
                        title: "常见问题管理",
                        meta: {
                            title: "常见问题管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/problem-list",
                        component: () =>
                            import("@/views/platform/problem-list"),
                    },
                    {
                        path: "/platform-list-cooperation_unit",
                        title: "战略合作单位",
                        meta: {
                            title: "战略合作单位",
                            icon: "md-albums",
                        },
                        componentPath: "platform/cooperation-list",
                        component: () =>
                            import("@/views/platform/cooperation-list"),
                    },
                    {
                        path: "/product-class-list",
                        title: "品种分类管理",
                        meta: {
                            title: "品种分类管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/product-class-list",
                        component: () =>
                            import("@/views/platform/product-class-list"),
                    },
                    {
                        path: "/platform-operation-list",
                        title: "运营方信息管理",
                        meta: {
                            title: "商城运营方信息管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/operation-list",
                        component: () =>
                            import("@/views/platform/operation-list"),
                    },
                ],
            },
            {
                path: "/integral",
                title: "积分管理",
                meta: {
                    title: "积分管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-list-integral",
                        title: "积分记录",
                        meta: {
                            title: "积分记录",
                            icon: "md-albums",
                        },
                        componentPath: "platform/integral-list",
                        component: () =>
                            import(
                                "@/views/platform/integral-search/integral-list"
                            ),
                    },
                    {
                        path: "/platform-integral-detail",
                        title: "积分详情",
                        meta: {
                            title: "积分详情",
                            icon: "md-albums",
                        },
                        componentPath: "platform/integral-detail",
                        component: () =>
                            import(
                                "@/views/platform/integral-search/integral-detail"
                            ),
                    },
                    {
                        path: "/platform-list-integral-rule",
                        title: "积分规则",
                        meta: {
                            title: "积分规则",
                            icon: "md-albums",
                        },
                        componentPath: "platform/integral--rule-list",
                        component: () =>
                            import("@/views/platform/integral-rule-list"),
                    },
                ],
            },
            {
                path: "/discount",
                title: "折扣管理",
                meta: {
                    title: "折扣管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-list-discount",
                        title: "折扣记录",
                        meta: {
                            title: "折扣记录",
                            icon: "md-albums",
                        },
                        componentPath: "platform/discount-list",
                        component: () =>
                            import("@/views/platform/discount-list"),
                    },
                    {
                        path: "/platform-list-discount-rule",
                        title: "折扣规则",
                        meta: {
                            title: "折扣规则",
                            icon: "md-albums",
                        },
                        componentPath: "platform/discount--rule-list",
                        component: () =>
                            import("@/views/platform/discount-rule-list"),
                    },
                ],
            },
            {
                path: "/transaction",
                title: "交易管理",
                meta: {
                    title: "交易管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-order-list",
                        title: "订单管理",
                        meta: {
                            title: "订单管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/order-list",
                        component: () => import("@/views/platform/order-list"),
                    },
                    {
                        path: "/platform-lading-list",
                        title: "提单管理",
                        meta: {
                            title: "提单管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/lading-list",
                        component: () => import("@/views/platform/lading-list"),
                    },
                    {
                        path: "/transaction-params-setting",
                        title: "交易参数设置",
                        meta: {
                            title: "交易参数设置",
                            icon: "md-albums",
                        },
                        componentPath: "platform/transaction-params-setting",
                        component: () =>
                            import(
                                "@/views/platform/transaction-params-setting"
                            ),
                    },
                    {
                        path: "/platform-listing",
                        title: "挂牌管理",
                        meta: {
                            title: "挂牌管理",
                            icon: "md-albums",
                        },
                        componentPath: "platform/listing",
                        component: () => import("@/views/platform/listing"),
                    },
                ],
            },
            {
                path: "/breach-contract",
                title: "违约管理",
                meta: {
                    title: "违约管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-whingers-list",
                        title: "质量异议",
                        meta: {
                            title: "质量异议",
                            icon: "md-albums",
                        },
                        componentPath: "platform/whingers-list",
                        component: () =>
                            import("@/views/platform/whingers-list"),
                    },
                ],
            },
            {
                path: "/riskControl",
                title: "风控管理",
                meta: {
                    title: "风控管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/platform-risk-control-list",
                        title: "风控记录",
                        meta: {
                            title: "风控记录",
                            icon: "md-albums",
                        },
                        componentPath: "platform/risk-control-list",
                        component: () =>
                            import("@/views/platform/risk-control-list"),
                    },
                    {
                        path: "/platform-risk-control-strategy",
                        title: "风控策略",
                        meta: {
                            title: "风控策略",
                            icon: "md-albums",
                        },
                        componentPath: "platform/risk-control-strategy",
                        component: () =>
                            import("@/views/platform/risk-control-strategy"),
                    },
                ],
            },
            {
                path: "/circulating-materials",
                title: "循环物资",
                meta: {
                    title: "循环物资",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/bidding-session",
                        title: "竞价场次",
                        meta: {
                            title: "竞价场次",
                            icon: "md-albums",
                        },
                        componentPath: "circulatingMaterials/list",
                        component: () => import("@/views/circulatingMaterials/list"),
                    },
                    {
                        path: "/bidding-add",
                        title: "竞价场次发布审核",
                        componentPath: "circulatingMaterials/add",
                        component: () =>
                            import("@/views/circulatingMaterials/add"),
                    },
                ],
            },
            {
                path: "/breach-contract",
                title: "竞价管理",
                meta: {
                    title: "竞价管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/bidding-sessions-list",
                        title: "竞价场次",
                        meta: {
                            title: "竞价场次",
                            icon: "md-albums",
                        },
                        componentPath: "bidding/sessions",
                        component: () => import("@/views/bidding/sessions"),
                    },
                    {
                        path: "/bidding-list",
                        title: "竞价管理",
                        meta: {
                            title: "竞价管理",
                            icon: "md-albums",
                        },
                        componentPath: "bidding",
                        component: () => import("@/views/bidding/bidding"),
                    },
                    {
                        path: "/bidding-detail",
                        title: "竞价详情",
                        meta: {
                            title: "竞价详情",
                            icon: "md-albums",
                        },
                        componentPath: "bidding",
                        component: () =>
                            import("@/views/bidding/bidding/detail"),
                    },
                ],
            },
            {
                path: "/customerService",
                title: "客户服务",
                meta: {
                    title: "客户服务",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/brandMerchants",
                        title: "品牌商家管理",
                        meta: {
                            title: "品牌商家管理",
                            icon: "md-albums",
                        },
                        componentPath: "brandMerchants",
                        component: () => import("@/views/platform/brand-merchants"),
                    },
                ],
            },
        ],
    },
    {
        path: "/operator-analysis",
        meta: {
            title: "运营分析",
        },
        title: "运营分析",
        component: ChildView,
        children: [
            {
                path: "/statistical-report",
                title: "统计报表",
                meta: {
                    title: "统计报表",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/stock-total-list",
                        title: "库存统计",
                        meta: {
                            title: "库存统计",
                            icon: "md-albums",
                        },
                        componentPath: "platform/stock-total-list",
                        component: () =>
                            import("@/views/platform/stock-total-list"),
                    },
                    {
                        path: "/profit-total-list",
                        title: "利润贡献统计",
                        meta: {
                            title: "利润贡献统计",
                            icon: "md-albums",
                        },
                        componentPath: "platform/profit-total-list",
                        component: () =>
                            import("@/views/platform/profit-total-list"),
                    },
                    {
                        path: "/gross-profit-total",
                        title: "毛利润统计",
                        meta: {
                            title: "毛利润统计",
                            icon: "md-albums",
                        },
                        componentPath: "platform/gross-profit-total",
                        component: () =>
                            import("@/views/platform/gross-profit-total"),
                    },
                    {
                        path: "/business-total",
                        title: "平台业务统计",
                        meta: {
                            title: "平台业务统计",
                            icon: "md-albums",
                        },
                        componentPath: "platform/business-total",
                        component: () =>
                            import("@/views/platform/business-total"),
                    },
                ],
            },
            {
                path: "/behavioral-analysis",
                title: "行为分析",
                meta: {
                    title: "行为分析",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/cr-analysis",
                        title: "转化率分析",
                        meta: {
                            title: "转化率分析",
                            icon: "md-albums",
                        },
                        componentPath: "platform/cr-analysis",
                        component: () => import("@/views/platform/cr-analysis"),
                    },
                    {
                        path: "/flow-analysis",
                        title: "流量分析",
                        meta: {
                            title: "转化率分析",
                            icon: "md-albums",
                        },
                        componentPath: "platform/flow-analysis",
                        component: () =>
                            import("@/views/platform/flow-analysis"),
                    },
                    {
                        path: "/crn-analysis",
                        title: "用户集中度趋势分析",
                        meta: {
                            title: "用户集中度趋势分析",
                            icon: "md-albums",
                        },
                        componentPath: "platform/crn-analysis",
                        component: () =>
                            import("@/views/platform/crn-analysis"),
                    },
                    {
                        path: "/csr-list",
                        title: "客户满意度分析",
                        meta: {
                            title: "客户满意度分析",
                            icon: "md-albums",
                        },
                        componentPath: "platform/csr-list",
                        component: () => import("@/views/platform/csr-list"),
                    },
                    {
                        path: "/behavior-analysis-list",
                        title: "用户行为分析",
                        meta: {
                            title: "用户行为分析",
                            icon: "md-albums",
                        },
                        componentPath: "platform/behavior-analysis",
                        component: () =>
                            import("@/views/platform/behavior-analysis"),
                    },
                ],
            },
        ],
    },
    {
        path: "/white-list-auth",
        meta: {
            title: "白名单认证",
        },
        title: "白名单认证",
        component: ChildView,
        children: [
            {
                path: "/white-list-audit",
                title: "白名单审核",
                meta: {
                    title: "白名单审核",
                    icon: "md-albums",
                },
                componentPath: "platform/white-list-audit",
                component: () => import("@/views/platform/white-list-audit"),
            },
            {
                path: "/white-list-edit",
                title: "审核认证资料",
                meta: {
                    title: "审核认证资料",
                    icon: "md-albums",
                },
                componentPath: "platform/white-list-edit",
                component: () =>
                    import("@/views/platform/white-list-audit/edit"),
            },
            {
                path: "/white-list-evaluate",
                title: "白名单评定",
                meta: {
                    title: "白名单评定",
                    icon: "md-albums",
                },
                componentPath: "platform/white-list-evaluate",
                component: () => import("@/views/platform/white-list-evaluate"),
            },
            {
                path: "/score-evaluate",
                title: "分数评定",
                meta: {
                    title: "分数评定",
                    icon: "md-albums",
                },
                componentPath: "platform/score-evaluate",
                component: () =>
                    import("@/views/platform/white-list-evaluate/edit"),
            },
        ],
    },
    {
        path: "/",
        name: "homePage",
        meta: {
            icon: "ios-alarm-outline",
            title: "首页",
            hide: true,
        },
        hide: true,
        componentPath: "homePage",
        component: () =>
            import(/* webpackChunkName: "home" */ "@/views/homePage"),
    },
    {
        path: "/home",
        name: "home",
        meta: {
            icon: "ios-alarm-outline",
            title: "项目",
            // hide: true
        },
        hide: true,
        componentPath: "home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/home"),
    },
    {
        path: "/gaikuang",
        meta: {
            icon: "icon-yijicaidanicon-gaikuang",
            title: "概况",
        },
    },
    {
        path: "/goods",
        meta: {
            title: "商品",
            icon: "icon-yijicaidanicon-shangpin",
        },
        title: "商品",
        component: ChildView,
        children: [
            {
                path: "/goods-manager",
                title: "商品管理",
                meta: {
                    title: "商品管理",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/goods-list-manager",
                        meta: {
                            title: "商品管理",
                            icon: "md-albums",
                            hideFooter: true,
                        },
                        title: "商品管理",
                        component: EmptyView,
                    },
                    {
                        path: "/price-rule",
                        meta: {
                            title: "价格规则",
                            icon: "md-albums",
                            hideFooter: true,
                        },
                        title: "价格规则",
                        component: EmptyView,
                    },
                ],
            },
            {
                path: "/goods-setting",
                title: "商品设置",
                meta: {
                    title: "商品设置",
                    icon: "md-albums",
                },
                component: ChildView,
                children: [
                    {
                        path: "/goods-list",
                        meta: {
                            title: "商品资料库",
                            icon: "md-albums",
                        },
                        title: "商品资料库",
                        component: () => import(/* webpackChunkName: "goods-list" */ "@/views/goods/goods-list"),
                    },
                    {
                        path: "/goods-attr",
                        meta: {
                            title: "商品属性管理",
                            icon: "md-albums",
                        },
                        title: "商品属性管理",
                        component: () => import(/* webpackChunkName: "goods-attr" */ "@/views/goods/goods-attr"),
                    },
                ],
            },
        ],
    },
    {
        path: "/setting",
        meta: {
            icon: "md-albums",
            title: "设置",
        },
        title: "设置",
        component: ChildView,
        children: [
            {
                path: "/company",
                meta: {
                    icon: "md-albums",
                    title: "公司设置",
                },
                title: "公司设置",
                component: ChildView,
                children: [
                    {
                        path: "/setting-company-company-list",
                        meta: {
                            title: "本方公司",
                            icon: "md-albums",
                        },
                        title: "本方公司",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-company-company-list" */ "@/views/setting/company/company/company-list"
                            ),
                    },
                    {
                        path: "/setting-company-company-edit",
                        meta: {
                            title: "编辑本方公司",
                            icon: "md-albums",
                            isFixedBtn: true,
                        },
                        hide: true,
                        title: "编辑本方公司",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-company-company-edit" */ "@/views/setting/company/company/company-edit"
                            ),
                    },
                    {
                        path: "/setting-company-organization-list",
                        meta: {
                            title: "组织机构",
                            icon: "md-albums",
                        },
                        title: "组织机构",
                        componentPath:
                            "setting/company/organization/organization-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-company-organization-list" */ "@/views/setting/company/organization/organization-list"
                            ),
                    },
                    {
                        path: "/setting-company-staff-list",
                        meta: {
                            title: "员工管理",
                            icon: "md-albums",
                        },
                        title: "员工管理",
                        componentPath: "setting/company/staff/staff-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-company-staff-list" */ "@/views/setting/company/staff/staff-list"
                            ),
                    },
                    {
                        path: "/setting-company-role-list",
                        meta: {
                            title: "角色管理",
                            icon: "md-albums",
                        },
                        title: "角色管理",
                        componentPath: "setting/company/role/role-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-company-role-list" */ "@/views/setting/company/role/role-list"
                            ),
                    },
                    {
                        path: "/setting-company-seal-list",
                        meta: {
                            title: "印章管理",
                            icon: "md-albums",
                            hideFooter: true,
                        },
                        title: "印章管理",
                        component: EmptyView,
                    },
                ],
            },
            {
                path: "/setting-finance",
                meta: {
                    title: "财务设置",
                    icon: "md-albums",
                },
                title: "财务设置",
                component: ChildView,
                children: [
                    {
                        path: "/setting-finance-payType",
                        meta: {
                            title: "支付方式",
                            icon: "md-albums",
                            hideFooter: true,
                        },
                        title: "支付方式",
                        component: EmptyView,
                    },
                    // {
                    //     path: "/capital-expense-type",
                    //     name: "expenseType",
                    //     componentPath: "capital/expense-type",
                    //     component: () =>
                    //         import(
                    //             /* webpackChunkName: "capital-expense-type" */ "@/views/capital/expense-type"
                    //         ),
                    //     meta: {
                    //         title: "费用类型",
                    //         icon: "md-albums",
                    //     },
                    //     title: "费用类型",
                    // },
                    {
                        path: "/setting-finance-use",
                        meta: {
                            title: "收付款用途",
                            icon: "md-albums",
                            hideFooter: true,
                        },
                        title: "收付款用途",
                        component: EmptyView,
                    },
                    {
                        path: "/setting-interest-rule-list",
                        meta: {
                            title: "计息设置",
                            icon: "md-albums",
                        },
                        title: "计息设置",
                        componentPath: "setting/interest-rule/rule-list",
                        component: () =>
                            import("@/views/setting/interest-rule/rule-list"),
                    },
                    {
                        path: "/setting-interest-rule-edit",
                        meta: {
                            title: "新增计息设置",
                            icon: "md-albums",
                            isFixedBtn: true,
                        },
                        hide: true,
                        title: "新增计息设置",
                        componentPath: "setting/interest-rule/rule-edit",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-interest-rules" */ "@/views/setting/interest-rule/rule-edit"
                            ),
                    },
                ],
            },
            {
                path: "/setting-basics",
                meta: {
                    title: "基础设置",
                    icon: "md-albums",
                },
                title: "基础设置",
                component: ChildView,
                children: [
                    {
                        path: "/customer-unit-list",
                        meta: {
                            title: "往来单位",
                            icon: "md-albums",
                        },
                        title: "往来单位",
                        componentPath: "customer/unit-manager/unitList",
                        component: () =>
                            import(
                                /* webpackChunkName: "customer-unit-manager-unitList" */ "@/views/customer/unit-manager/unitList"
                            ),
                    },
                    {
                        path: "/customer-unit-edit",
                        meta: {
                            title: "新增往来单位",
                            isFixedBtn: true,
                            icon: "md-albums",
                        },
                        hide: true,
                        title: "新增往来单位",
                        componentPath: "customer/unit-manager/unitAdd",
                        component: () =>
                            import(
                                /* webpackChunkName: "customer-unit-manager-unitAdd" */ "@/views/customer/unit-manager/unitAdd"
                            ),
                    },
                ],
            },
            {
                path: "/setting-sys",
                meta: {
                    title: "系统设置",
                    icon: "md-albums",
                },
                title: "系统设置",
                component: ChildView,
                children: [
                    {
                        path: "/sys-dictManageList",
                        name: "dictManageList",
                        componentPath: "sys/dictManager/dictList",
                        component: () =>
                            import(
                                /* webpackChunkName: "customer-dictManager-dictList" */ "@/views/sys/dictManager/dictList"
                            ),
                        meta: {
                            title: "数据字典",
                            icon: "md-albums",
                        },
                        title: "数据字典",
                    },
                    {
                        path: "/setting-configure-list",
                        meta: {
                            title: "配置项管理",
                            icon: "md-albums",
                        },
                        title: "配置项管理",
                        componentPath: "setting/configure/configure-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "setting-configure-configure-list" */ "@/views/setting/configure/configure-list"
                            ),
                    },
                ],
            }
        ],
    },
];
export default [
    ...viewRoutes,
    {
        path: "/login",
        name: "login",
        meta: {
            title: "登录",
        },
        hide: true,
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/login/login"),
    },
    {
        path: "/notFound",
        name: "error",
        meta: {
            title: "404",
        },
        hide: true,
        component: () =>
            import(/* webpackChunkName: "notFound" */ "@/views/notFound"),
    },
];
